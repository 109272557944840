<template>
  <div class="colors-container">
    <div class="title">
      <h2>Página de acesso</h2>
      <h3>Página onde seus usuários realizarão o login.</h3>
    </div>
    <div class="colors">
      <div class="input">
        <h2>Cor de fundo</h2>
        <h3>Cor de fundo</h3>
        <color-picker
          class="color-picker"
          type="color"
          :color="data.background"
          @input="data.background = $event"
        />
      </div>
    </div>
    <div class="colors">
      <div class="input">
        <h2>Cor de texto</h2>
        <h3>Cor de texto principal</h3>
        <color-picker
          class="color-picker"
          type="color"
          :color="data.mainText"
          @input="data.mainText = $event"
        />
      </div>
    </div>
    <div class="colors">
      <h2>Cores dos botões</h2>
      <div class="multiple">
        <div class="input">
          <h3>cor do botão primário</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.mainButton"
            @input="data.mainButton = $event"
          />
        </div>
        <div class="input">
          <h3>texto do botão primário</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.mainButtonText"
            @input="data.mainButtonText = $event"
          />
        </div>
        <div class="input">
          <h3>botão secundário + texto</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.secondaryButton"
            @input="data.secondaryButton = $event"
          />
        </div>
      </div>
    </div>
    <div class="colors">
      <h2>Cores dos detalhes</h2>
      <div class="multiple">
        <div class="input">
          <h3>cor dos divisores</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.divisors"
            @input="data.divisors = $event"
          />
        </div>
        <div class="input">
          <h3>”ou”</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.or"
            @input="data.or = $event"
          />
        </div>
        <div class="input">
          <h3>”esqueci a senha”</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.forgotPassword"
            @input="data.forgotPassword = $event"
          />
        </div>
        <div class="input">
          <h3>feedback de erro</h3>
          <color-picker
            class="color-picker"
            type="color"
            :color="data.negativeFeedback"
            @input="data.negativeFeedback = $event"
          />
        </div>
      </div>
    </div>
    <div class="preview">
      <div class="title">
        <h2>Pré-visualização</h2>
        <div class="options">
          <div class="check">
            <c-checkbox
              round
              class="checkbox"
              :value="isDesktopPreview"
              @input="isDesktopPreview = true"
            />  Desktop
          </div>
          <div class="check">
            <c-checkbox
              round
              class="checkbox"
              :value="!isDesktopPreview"
              @input="isDesktopPreview = false"
            />  Mobile
          </div>
        </div>
      </div>
      <login-page
        v-if="isDesktopPreview"
        :data="data"
      />
      <div
        v-else
        class="mobile-container"
      >
        <div class="mobile">
          <login-page
            :is-mobile="true"
            :data="data"
          />
        </div>
      </div>
    </div>
    <footer-buttons
      :is-valid="!disableNextButton"
      @save="$emit('save', data)"
      @back="openConfirmationModal = true"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$router.push('/personalizacao')"
    />
  </div>
</template>
<script>
import CCheckbox from '@/components/CComponents/CCheckbox.vue'
import FooterButtons from '@/components/Settings/general/FooterButtons.vue'
import LoginPage from '@/components/Customization/Preview/LoginPage.vue'
import ColorPicker from '@/components/Customization/general/ColorPicker.vue'
import CConfirmModal from '@/components/CComponents/CConfirmModal.vue'
export default {

  components: {
    LoginPage,
    CCheckbox,
    FooterButtons,
    ColorPicker,
    CConfirmModal
  },

  props: {
    company: Object
  },

  data () {
    return {
      data: {
        logo: null,
        signInLogo: null,
        background: '#FFFFFF',
        mainText: '#202950',
        mainButton: '#121E48',
        mainButtonText: '#ffffff',
        secondaryButton: '#d8d8d8',
        divisors: '#d8d8d8',
        or: '#d8d8d8',
        forgotPassword: '#d8d8d8',
        negativeFeedback: '#d8d8d8',
        developedByConvenia: true
      },
      openConfirmationModal: false,
      isDesktopPreview: true
    }
  },

  computed: {
    disableNextButton () {
      const keys = [
        'background',
        'mainText',
        'mainButton',
        'mainButtonText',
        'secondaryButton',
        'divisors',
        'forgotPassword',
        'negativeFeedback'
      ]
      let validated = true
      keys.forEach(key => {
        if (this.data[key] === '' || this.data[key] === null) validated = false
      })
      return validated
    }
  },

  mounted () {
    if (this.company.signIn) {
      this.data = JSON.parse(JSON.stringify(this.company.signIn))
    }
    if (this.company.signInLogo) {
      if (typeof this.company.signInLogo === 'string') {
        this.data.logo = this.company.signInLogo
      } else if (this.company.signInLogo.length !== 0) {
        this.data.logo = URL.createObjectURL(this.company.signInLogo)
        this.data.signInLogo = this.company.signInLogo
      }
    }

    this.data.intro = this.company.intro
    this.data.developedByConvenia = this.company.developedByConvenia
    if (this.company.backgroundImage && this.company.backgroundImage.length !== 0) {
      this.data.backgroundImage = this.company.backgroundImage
    }
  },

  methods: {
    changeData (event, key) {
      this.data[key] = event
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';
.colors-container {
   & > .title {
    display: flex;
    flex-direction: column;
    margin: 48px 0;

    & > h2 {
      align-items: center;
      display: flex;
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    & > h3 {
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  & > .colors {
    & > h2 {
        color: $neutral-dark-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: 24px;
      }

    & > .input {
      margin-bottom: 32px;
      & > h2 {
        color: $neutral-dark-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: 24px;
      }

      & > h3 {
        color: $neutral-gray-200;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.5px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      & > .color-picker {
        width: 321px;
      }
    }

    & > .multiple {
      display: flex;
      width: 682px;
      flex-wrap: wrap;
       & > .input {
        margin-bottom: 32px;
        & > h2 {
          color: $neutral-dark-blue;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 24px;
        }

        & > h3 {
          color: $neutral-gray-200;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 13.5px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }

        & > .color-picker {
          width: 321px;
        }
      }

      & > .input:nth-child(2) {
        margin-left: 40px;
      }

      & > .input:nth-child(4) {
        margin-left: 40px;
      }
    }
  }
  & > .preview {
    & > .title {
      display: flex;
      align-items: self-end;
      margin-bottom: 24px;
      & > h2 {
        align-items: center;
        display: flex;
        color: $neutral-dark-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      & > .options{
        margin-top: 16px;
        display: flex;
        width: 122px;
        justify-content: space-between;

        & > .check {
          display: flex;
          color: $neutral-gray-200;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          align-items: center;
           margin-left: 40px;
          & > .checkbox {
            margin-right: 8px;
            margin-bottom: 0;
          }
        }
      }
    }
     & > .mobile-container {
      display: flex;
      justify-content: center;
      width: 1020px;
      & > .mobile {
        transition: all ease-in-out 0.5s;
        margin: 0 auto;
      }
    }
  }
}
</style>
