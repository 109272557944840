<template>
  <div class="brand-container">
    <div class="title">
      <h2>Página de acesso</h2>
      <h3>Página onde seus usuários realizarão o login.</h3>
    </div>
    <div class="form">
      <div class="label">
        Título da página <info-button :info="infos[0]" />
      </div>
      <div class="input-div">
        <c-input
          class="input"
          :disabled="disableTitle"
          placeholder="Ex: Meu Clube"
          v-model="data.title"
          required
          maxlength="20"
          v-validate="'required'"
        />
        <span class="subtext">Máx. 20 caracteres</span>
      </div>
      <warn-label
        :type="disableTitle ? 2 : 1"
        @input="warn = $event"
      />
    </div>
    <div class="form">
      <div class="label">
        texto de introdução <info-button :info="infos[1]" />
      </div>
      <c-input
        class="input -textarea"
        maxlength="80"
        minlength="3"
        textarea
        placeholder="Ex: Economize na compra de produtos e serviços da marcas que você mais curte."
        v-model="data.intro"
      />
      <h3>Máx. 80 caracteres</h3>
    </div>
    <div class="images">
      <div class="upload">
        <h3>Logotipo</h3>
        <upload-image
          id="logo"
          class="upload-image"
          placeholder="logo"
          :max-size="300"
          :required-width="182"
          :required-height="66"
          :is-edit="isEdit.signInLogo"
          @error="logoImageValidation = !$event"
          @change="handleImage($event, 'signInLogo')"
        />
        <span class="size">Tamanho: <span class="value">182 x 66 px</span></span>
      </div>
      <div class="upload">
        <h3>Favicon</h3>
        <upload-image
          id="favicon"
          class="upload-image"
          placeholder="favicon"
          :max-size="300"
          :required-width="48"
          :required-height="48"
          :is-edit="isEdit.favicon"
          @error="faviconImageValidation = !$event"
          @change="handleImage($event, 'favicon')"
        />
        <span class="size">Tamanho: <span class="value">48 x 48 px</span></span>
      </div>
      <div class="upload">
        <h3>Imagem background</h3>
        <upload-image
          id="backgroundImage"
          class="upload-image"
          placeholder="background"
          :is-edit="isEdit.backgroundImage"
          :max-size="300"
          :required-width="1024"
          :required-height="768"
          @error="backgroundImageValidation = !$event"
          @change="handleImage($event, 'backgroundImage')"
        />
        <span class="size">Tamanho: <span class="value">1024 x 768 px</span></span>
      </div>
    </div>
    <div class="form">
      <div class="label">
        mostrar "desenvolvido por kaledo?”
      </div>
      <div class="options">
        <div class="check">
          <c-checkbox
            :disabled="company.trial"
            round
            class="checkbox"
            @input="!company.trial ? data.developedByConvenia = true : {}"
            :value="data.developedByConvenia "
          />  Sim
        </div>
        <div class="check">
          <c-checkbox
            :disabled="company.trial"
            round
            class="checkbox"
            @input="!company.trial ? data.developedByConvenia = false : {}"
            :value="!data.developedByConvenia "
          />  Não
        </div>
      </div>
    </div>
    <footer-buttons
      :is-valid="!disableNextButton"
      save-button-text="Avançar"
      @save="$emit('nextStep', data)"
      @back="openConfirmationModal = true"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$router.push('/personalizacao')"
    />
  </div>
</template>

<script>
import WarnLabel from '@/components/Customization/general/WarnLabel.vue'
import UploadImage from '@/components/General/UploadImage.vue'
import InfoButton from '@/components/Settings/general/InfoButton'
import CCheckbox from '@/components/CComponents/CCheckbox.vue'
import FooterButtons from '@/components/Settings/general/FooterButtons.vue'
import CConfirmModal from '@/components/CComponents/CConfirmModal.vue'
import { maxLengthValidation } from '@/modules/validate/validators.js'

export default {
  components: {
    WarnLabel,
    UploadImage,
    InfoButton,
    CCheckbox,
    FooterButtons,
    CConfirmModal
  },

  props: {
    company: Object
  },

  data () {
    return {
      isEdit: {
        signInLogo: false,
        backgroundImage: false,
        favicon: false
      },
      disableTitle: false,
      logoImageValidation: false,
      faviconImageValidation: false,
      backgroundImageValidation: false,
      infos: [
        {
          text: 'O nome da sua página de acesso, podendo ser o nome do seu clube.'
        },
        {
          text: 'Uma breve introdução sobre o seu clube para seus usuários.'
        }
      ],
      data: {},
      warn: false,
      warnType: 1,
      openConfirmationModal: false
    }
  },

  computed: {
    titleValidation () {
      return maxLengthValidation(this.data.title, 255)
    },
    imagesValidation () {
      return this.logoImageValidation && this.faviconImageValidation && this.backgroundImageValidation
    },
    disableNextButton () {
      const keys = ['title', 'intro'] /* */
      let validated = true
      if (this.warn === false) validated = false
      keys.forEach(key => {
        if (this.data[key] === '' || this.data[key] === null || this.data[key] === undefined) validated = false
      })

      return validated && this.titleValidation && this.imagesValidation
    }
  },
  watch: {
    company: {
      handler (newval) {
        this.updateData()
        this.checkCompanyTitleValidation()
      },
      deep: true
    }
  },

  mounted () {
    this.updateData()
    const validations = {
      signInLogo: 'logoImageValidation',
      favicon: 'faviconImageValidation',
      backgroundImage: 'backgroundImageValidation'
    }

    Object.entries(validations).forEach(([key, validation]) => {
      if (typeof this.data[key] === 'string') {
        this[validation] = true
      }
    })
    this.checkCompanyTitleValidation()
  },

  methods: {
    checkCompanyTitleValidation () {
      if (this.company.title && this.company.title.length > 0) {
        this.warn = true
        this.warnType = 2
        this.disableTitle = true
      }
    },
    handleImage (files, key) {
      if (files && files.length === 0) {
        this.data[key] = null
        this.isEdit[key] = false
      }
      this.data[key] = files
      this.isEdit[key] = true
    },
    updateData () {
      this.data = { ...this.company }
      const imageKeys = ['signInLogo', 'backgroundImage', 'favicon']
      imageKeys.forEach(key => {
        if (this.data[key] && typeof this.data[key] === 'string') this.isEdit[key] = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.brand-container {
  & > .title {
    display: flex;
    flex-direction: column;
    margin: 48px 0;

    & > h2 {
      align-items: center;
      display: flex;
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    & > h3 {
      color: $primary-neutral-2;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  & > .images {
    display: flex;
    margin-bottom: 48px;
    & > .upload {
      & > h3 {
        color: $primary-neutral-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.5px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      & > .upload-image {
        background-color: #FFF;
        margin-bottom: 8px;
      }

      & > .size {
        color: $neutral-dark-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        & > .value {
          font-weight: 600 !important;
          margin-left: 8px;
        }
      }

    }

    & > .upload:nth-child(2) {
      margin: 0 48px;
    }
  }

  & > .form {
      padding-bottom: 48px;
      & > .label {
        display: flex;
        align-items: center;
        height: 20px;
        color: $primary-neutral-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      & > .input-div {
        width: 328px;
        & > .input {
          margin-top: 3px;
        }
        & > .subtext {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: -0.121905px;
          color: #5E6684;
          margin-top: 6px;
          margin-bottom: 16px;
        }
      }

      & > .-textarea {
        padding-bottom: 8px !important;

        :deep textarea {
          width: 328px !important;
          resize: none;
        }
      }

      & > h3 {
        color: $primary-neutral-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.122px;
      }

      & > .options{
        margin-top: 16px;
        display: flex;
        width: 122px;
        justify-content: space-between;
        flex-direction: column;

        & > .check {
          display: flex;
          color: $primary-neutral-2;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          align-items: center;
          margin-bottom: 8px;

          & > .checkbox {
            margin-right: 8px;
          }
        }
      }
    }
}
</style>
